<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		
		<div class="security" v-loading="loading">
			<div class="item-wrap">
				<div class="item">
					<div class="item-content">
						<div class="name-wrap">
							<div class="name">安装服务</div>
						</div>
					</div>
					<div class="btn" @click="toWorkerOrder('install')"><el-button type="primary" size="medium">{{ server_data.install_work_order_info && server_data.install_work_order_info.order_status_name ? server_data.install_work_order_info.order_status_name : '发起服务'}}</el-button></div>
				</div>
				<div class="item">
					<div class="item-content">
						<div class="name-wrap">
							<div class="name">维修服务</div>
						</div>
					</div>
					<div class="btn" @click="toWorkerOrder('maintain')"><el-button type="primary" size="medium">发起服务</el-button></div>
				</div>
			</div>
			<div class="card_wrap">
				<div class="tit">服务卡列表</div>
				<div class="card_list" v-if="card_list.length">
					<div class="card-item" v-for="(item, index) in card_list" :key="index" @click="toDetail(item.sku_id)">
					  <img class="img-wrap" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" />
					  <div class="price-wrap">
					    <div class="price">
					      <p>￥</p>
					      {{ item.discount_price }}
					    </div>
						<div class="right">{{item.verify_num}}次卡</div>
					  </div>
					  <div class="goods-name">{{ item.sku_name }}</div>
					</div>
				</div>
				<div v-else class="empty_card">暂无服务卡</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { workOrderCreateData ,cardList } from '@/api/order/work_order'
	export default {
		data() {
			return {
				loading: true,
				yes: true,
				order_goods_id:'',
				order_id:'',
				server_data:{},
				card_list:[]
			}
		},
		created() {
			if(this.$route.query.order_goods_id) this.order_goods_id = this.$route.query.order_goods_id;
			if(this.$route.query.order_id) this.order_id = this.$route.query.order_id;
			this.getCreateData();
		},
		mounted() {
			setTimeout(()=>{
				this.yes = false
			}, 300)
		},
		methods: {
			toDetail(skuId){
				this.$router.push({path:'/cardSku',query:{
					sku_id:skuId,
					sale_store_id:this.server_data.order_goods_info.store_id
				}});
			},
			getCardList(){
				cardList({
					page: 1,
					page_size:0,
					goods_id:this.server_data.order_goods_info.goods_id,
				})
				.then(res=>{
					this.card_list = res.data.list;
				})
				.catch(err=>{})
			},
			toWorkerOrder(mode){
				switch (mode){
					case 'install':
						//安装
						if(this.server_data.install_work_order_info && this.server_data.install_work_order_info.work_order_id){
							this.$router.push({path:'/member/work_order_detail',query:{work_order_id : this.server_data.install_work_order_info.work_order_id }});
						}else{
							this.$router.push({path:'/member/create_work_order',query:{order_goods_id : this.order_goods_id,work_type : 1}});
						}
						break;
					case 'maintain':
						//维修
						if(this.server_data.repair_work_order_info && this.server_data.repair_work_order_info.work_order_id){
							this.$router.push({path:'/member/work_order_detail',query:{work_order_id : this.server_data.repair_work_order_info.work_order_id }});
						}else{
							this.$router.push({path:'/member/create_work_order',query:{order_goods_id : this.order_goods_id,work_type : 2}});
						}
						break;
					default:
						break;
				}
			},
			getCreateData(){
				workOrderCreateData({
					order_goods_id:this.order_goods_id
				})
				.then(res=>{
					if(res.code>=0){
						this.server_data = res.data;
						this.getCardList();
						this.loading = false;
					}else{
						this.$message({
							message: '未获取到该订单项服务信息',
							type: 'error',
							duration: 2000,
							onClose: () => {
								this.$router.push({
									path: '/member/order_detail',
									query:{
										'order_id':this.order_id
									}
								});
							}
						});
					}
				})
				.catch(err=>{
					this.$message.error(err.message)
				})
			},
		},
	}
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}	

.security {
	// background: #ffffff;
	.item-wrap{
		background-color: #fff;
	}
	.item {
		border-bottom: 1px solid #f1f1ff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px;
		.item-content {
			display: flex;
			align-items: center;
			.name-wrap {
				margin-left: 20px;
			}
		}
	}
}
.card_wrap{
	background-color: #fff;
	padding: 20px;
	margin-top: 15px;
	.tit{
		padding-left: 20px;
		margin-bottom: 20px;
	}
	.empty_card{
		text-align:center;
	}
	.card_list{
		display: flex;
		flex-wrap: wrap;
		.card-item {
			  width: 220px;
			  margin: 10px 20px 0 0;
			  border: 1px solid #eeeeee;
			  padding-top: 10px;
			  position: relative;
			  padding-bottom: 5px;
			  box-sizing: border-box;
			  &:nth-child(4n) {
			    margin-right: initial !important;
			  }
			  &:hover {
			    border: 1px solid $base-color;
			  }
			  .img-wrap {
			    width: 198px;
			    height: 198px;
			    cursor: pointer;
			    padding: 10px;
			  }
			  .goods-name {
			    padding: 0 10px;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    display: -webkit-box;
			    -webkit-box-orient: vertical;
			    -webkit-line-clamp: 2;
			    word-break: break-all;
			    height: 50px;
			    cursor: pointer;
			    &:hover {
			      color: $base-color;
			    }
			  }
			  .price-wrap {
			    padding: 0 10px;
			    display: flex;
			    align-items: center;
				justify-content: space-between;
			    .price {
			      display: flex;
			      color: $base-color;
			      font-size: $ns-font-size-lg;
			      p {
			        font-size: $ns-font-size-base;
			        display: flex;
			        align-items: flex-end;
			      }
			    }
			    .market-price {
			      color: #838383;
			      text-decoration: line-through;
			      margin-left: 10px;
			    }
			  }
			  .sale-num {
			    padding: 0 10px;
			    display: flex;
			    color: #838383;
			     font-size: $ns-font-size-sm;
			    p {
			      color: #4759a8;
			    }
			  }
			  .shop_name {
			    padding: 0 10px;
			    display: flex;
			    color: #838383;
			  }
			  .saling {
			    padding: 0 10px;
			    display: flex;
			    font-size: $ns-font-size-sm;
			    line-height: 1;
			    .free-shipping {
			      background: $base-color;
			      color: #ffffff;
			      padding: 3px 4px;
			      border-radius: 2px;
			      margin-right: 5px;
			    }
			    .is_own {
			      color: #ffffff;
			      background: #ff850f;
			      border: 1px solid;
			      margin-right: 5px;
			      display: flex;
			      align-items: center;
			      padding: 3px 4px;
			      border-radius: 2px;
			    }
			    .promotion-type {
			      color: $base-color;
			      border: 1px solid $base-color;
			      display: flex;
			      align-items: center;
			      padding: 1px 3px;
			    }
			  }
			  .item-bottom {
			    display: flex;
			    margin-top: 5px;
			    .collection {
			      flex: 1;
			      border: 1px solid #e9e9e9;
			      border-right: none;
			      border-left: none;
			      height: 40px;
			      display: flex;
			      align-items: center;
			      justify-content: center;
			      cursor: pointer;
			    }
			    .cart {
			      flex: 2;
			      border: 1px solid #e9e9e9;
			      border-right: none;
			      height: 40px;
			      display: flex;
			      align-items: center;
			      justify-content: center;
			      cursor: pointer;
			    }
			}
		}
	}
}
</style>
